<template>
  <div>
    <v-dialog
      v-model="viewDialog"
      persistent
      scrollable
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Shareholder Info') }}
          <v-spacer />
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon class="mdil-36px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="mb-14"
              style="position: relative"
            >
              <div
                style="border: 1px solid #ccc"
                class="rounded-lg"
              >
                <v-img
                  :src="`${$_file_path()}${instance.cover_picture}?token=${$_auth_token()}`"
                  width="100%"
                  height="150px"
                  contain
                />
              </div>

              <v-img
                :src="`${$_file_path()}${instance.profile_picture}?token=${$_auth_token()}`"
                width="100%"
                height="70px"
                contain
                style="position: absolute; bottom: -1.5rem; right: 0"
              />
            </v-col>
          </v-row>

          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="key-td">
                    {{ $t('Shareholder Name') }}
                  </td>
                  <td class="value-td">
                    {{ instance.username }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Full Name') }}
                  </td>
                  <td class="value-td">
                    {{ instance.first_name }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Share Percentage %') }}
                  </td>
                  <td class="value-td">
                    {{ instance.share_percentage }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Phone') }}
                  </td>
                  <td class="value-td">
                    {{ instance.phone }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Type') }}
                  </td>
                  <td class="value-td">
                    {{ instance.type.name }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('ID Cart') }}
                  </td>
                  <td class="value-td">
                    {{ instance.id_cart }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Occupation') }}
                  </td>
                  <td class="value-td">
                    {{ instance.occupation }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Degree') }}
                  </td>
                  <td class="value-td">
                    {{ instance.degree }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Nickname') }}
                  </td>
                  <td class="value-td">
                    {{ instance.nickname }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Business Name') }}
                  </td>
                  <td class="value-td">
                    {{ instance.business_name }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Martial Status') }}
                  </td>
                  <td class="value-td">
                    {{ instance.marital_status == 1 ? $t('Single') : $t('Married') }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Husband/Wife Name') }}
                  </td>
                  <td class="value-td">
                    {{ instance.spouse_name }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Mother Name') }}
                  </td>
                  <td class="value-td">
                    {{ instance.mother_name }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Dos Number Tax Office') }}
                  </td>
                  <td class="value-td">
                    {{ instance.dos_number_tax_office }}
                  </td>
                </tr>
                <tr>
                  <td class="key-td">
                    {{ $t('Address') }}
                  </td>
                  <td class="value-td">
                    {{ instance.address }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table
            v-if="instance.babies && instance.babies.length"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('Child Name') }}</th>
                  <th>{{ $t('Birth Date') }}</th>
                  <th>{{ $t('Occupation') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(baby, index) in instance.babies"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ baby.name }}</td>
                  <td>{{ baby.dob }}</td>
                  <td>{{ baby.occupation }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    viewDialog: { type: Boolean, default: false },
    instance: { type: Object, default: () => { } },
  },
}
</script>
<style>
.key-td {
  width: 200px;
}
</style>